import React from "react";
import "../../css/home/home.css";
import Answers from "../answers/answers";
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

export default class Discussions extends React.Component{ 

   constructor(props) {
      super(props);
      this.state = {
         loading: true,
         payload: null
      };

      this.cookies = new Cookies();
      this.cookieString = this.cookies.get('data');
   }
   

   async componentDidMount(){
      const url = process.env.REACT_APP_API_HOST + "discussions/getall";
      const response= await fetch(url, {
         method: 'POST',
         headers: getHeadersAuthorization(this.cookieString)
      }).then(function(response) {
         if(response.status === 401) {
            //devo ri-instanziare Cookies perché ancora non esiste
            let cook = new Cookies();
            cook.remove('data', { path: '/' });
            window.location.replace("/login");
         } else {
            return response;
         }
      });

      const data = await response.json();
      this.setState({payload: data, loading: false});
   }

   formatdata(date){
      let d = new Date(date);
      let today_year = new Date().getFullYear();
      let today_day = new Date().getDate();
      let today_month = new Date().getMonth() + 1;

      let da = d.getDate();
      let mo_number = d.getMonth() + 1;
      let mo = new Intl.DateTimeFormat('it', { month: 'short' }).format(d).toLowerCase();
      let ye = d.getFullYear();
      let hr = d.getHours();
      let mi = d.getMinutes();
      if(mi.toString().length===1){
         mi='0'+mi;
      }
      //let se = d.getSeconds();

      let timetostamp = '';
      let ieri_oggi = null;
      if(today_year > ye){
         timetostamp = da+' '+mo+' '+ye+' alle '+hr+':'+mi;
      } else {
         //se è nello stesso anno controllo se è anche lo stesso giorno per scrivere 'oggi' o 'ieri'
         if (da === today_day && mo_number === today_month) {
            ieri_oggi = 'oggi';
         } else if ((today_day-da) === 1 && mo_number === today_month) {
            ieri_oggi = 'ieri';
         }

         if(ieri_oggi) {
            timetostamp = ieri_oggi+' alle '+hr+':'+mi;
         } else {
            timetostamp = da+' '+mo+' alle '+hr+':'+mi;
         }
      }

      return timetostamp;
   }

   render(){ 

      return( 
         <div className = "home-content">
            <div id="for-adv" className="row">
               <div className="col-md-2 d-md-block d-none">
                  {/* <SideBanner /> */}
               </div>
               <div className = "col-md-8">
                  <div className = "container">
                     <div className="row">
                        <h1>Discussioni</h1>
                        <div className="ml-3 mt-2"><a className="btn btn-primary" href="/discussions/add">+ crea</a></div>
                     </div>
                     {this.state.loading || !this.state.payload ? (
                        <div className="item-centered">
                           <Spinner className="disc-name-color" animation="border" role="status">
                              <span className="d-none">Loading...</span>
                           </Spinner>
                        </div>
                     ) : (
                     <div>
                        {this.state.payload.items.map((item, index) => (
                        <div key={item.id}>
                           <div className="card">
                                 <div className="card-header">
                                    <b className="disc-name-color">{item.user}</b> chiede
                                       {/*<div className="float-right text-muted">{this.formatdata(item.created)}</div>*/}
                                 </div>
                                 <div className="card-body">
                                       <h5 className="card-title">{item.title}</h5>
                                       <p className="card-text">{item.description}</p>
                                       <p className="card-text">
                                          <small className="text-muted">{this.formatdata(item.created)}</small>
                                       </p>
                                       <Answers id_disc={item.id} />
                                 </div>
                              </div>
                              {/* {( (index+1) % 2 === 0) ? (<div className="card"><div className="card-body adv"><center>Banner in vendita, <a href="/contactus">contattaci</a>.</center></div></div>): '' } */}
                        </div>
                        ))}
                     </div>
                     )}
                  </div>
               </div>
               <div className="col-md-2 d-md-block d-none">
                  {/* <SideBanner /> */}
               </div>
            </div>
         </div>
      ); 
   } 
}