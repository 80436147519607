import React, {useState} from "react";
import {Button, Modal} from 'react-bootstrap';
import elabLogo from "../../images/elab-1.png";
import discLogo from "../../images/disc-icon.png";

function YouMustBeLogged(props) {

    const section = props.section;
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const redirectToLogin = () => window.location.replace("/login");
    const redirectToRegister = () => window.location.replace("/register");
    
    return (
      <>
        <button className="nav-item nav-link btn-as-link" onClick={handleShow}>
          {section === 'Elaborazioni' ? (
            <img src={elabLogo} width="35px" height="35px" alt="elab-logo"/>
          ) : (
            <img src={discLogo} width="35px" height="35px" alt="disc-logo"/>
          ) }
        </button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{section}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className = "container">
              <div className = "row">
                <div className = "col-12">
                  <p>Per entrare nella sezione <b>{section}</b> devi essere loggato.<br/>
                  <div className="button-login-wrapper mt-3">
                    <Button variant="primary" className="mr-3" onClick={redirectToLogin}>
                      Accedi
                    </Button>
                    <Button variant="primary" onClick={redirectToRegister}>
                      Registrati
                    </Button>
                  </div>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default YouMustBeLogged;