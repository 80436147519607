import React from "react";
import JoyRide from "react-joyride";

// Tour steps
const TOUR_STEPS = [
  {
    target: ".first-step",
    title: "Benvenuto in RACEMOOD",
    content: "Ti spieghiamo al volo come funziona",
    disableBeacon: true
  },
  {
    target: ".second-step",
    content: "Qui puoi navigare nelle 3 pagine principali"
  },
  {
    target: ".elab-step",
    title: "Elaborazioni",
    content: "Questa è la pagina home dove sono contenuti tutti i nuovi post"
  },
  {
    target: ".hp-step",
    title: "Calcolatore Cavalli",
    content: "Questa pagina serve per sapere in anticipo i cavalli che raggiungerà tua auto a seguito di varie modifiche"
  },
  {
    target: ".disc-step",
    title: "Discussioni",
    content: "Qui puoi fare delle domande alla community se hai un problema o un dubbio sulla tua auto"
  },
  {
    target: ".like-step",
    title: "Tasto like",
    content: "Se ti piace un'elaborazione questo è il modo giusto per farlo sapere. Tutti i post che raggiungono i 100 ottani avranno la possiblità di essere pubblicati sulla nostra pagina instagram!"
  },
  {
    target: ".menu-step",
    content: "Infine, premendo sul tuo username potrai trovare: i tuoi post, il tuo account, la sezione per contattarci ed il tasto per effettuare il logout"
  }
];

// Tour component
const Tour = ({start, JoyrideCallback}) => {
  return (
    <>
      <JoyRide
        run={start}
        callback={JoyrideCallback}
        steps={TOUR_STEPS}
        continuous={true}
        showSkipButton={true}
        scrollOffset="80"
        showProgress={true}
        disableOverlayClose={true}
        styles={{
          options: {
            primaryColor: '#3300ff',
            zIndex: 1040,
          }
        }}
        locale={{
          last: "Termina tour",
          skip: "Termina tour",
          next: "Avanti",
          back: "Indietro",
        }}
      />
    </>
  );
};

export default Tour;