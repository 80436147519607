import React from "react";
import "../../css/home/home.css";
import "../../css/home/slider.css";
import Comment from "../comment/comment.js";
import ImageGallery from 'react-image-gallery';
import tankImg from "../../images/fuel-tank.png";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Spinner from 'react-bootstrap/Spinner';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import Tour from "../tour/tour";
import InfiniteScroll from 'react-infinite-scroll-component';
import AppBanner from 'smart-app-banner-react';
import {isMobile} from 'react-device-detect';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

export default class Home extends React.Component{ 

   constructor(props) {
      super(props);
      this.state = {
         loading: true,
         payload: null,
         showVideo: {},
         likes: null,
         tourEnjoyed: 1,
         hasMore: true,
         page: 1
      };

      this.cookies = new Cookies();
      this.data = '';
      this.appInfo = {
         imageUrl:
           'https://play-lh.googleusercontent.com/KRDcX3PRcf3C9ArIG4j3phLxiAZX49kCPNeHqKhtCHcJ33KX1-YjqVQATvwP0xcMWi4=w480-h960-rw',
         name: 'Racemood',
         publisher: 'Racemood Inc.',
         infoText: 'Gratis - Play Store',
         linkButtonText: 'Apri',
         linkUrl:
           'https://play.google.com/store/apps/details?id=com.racemood.app'
       }

      this.cookieString = this.cookies.get('data');
      if(this.cookieString !== undefined){
         this.data = CryptoJS.decrypt(this.cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8).split('%');
         //data[0]=uid, data[1]=token, data[2]=username
      }

      this.displayAppBanner = true;
      this.appBanner = this.cookies.get('appBanner');
      if(this.appBanner !== undefined){
         let today = new Date();
         let cookieDate = new Date(this.appBanner);
         if(cookieDate > today) {
            this.displayAppBanner = false;
         }
      }
   }

   async componentDidMount(){
      this.trickToHideAppBanner();
      
      const url = process.env.REACT_APP_API_HOST + "elaborations/getall/v2/"+this.data[0]+"/"+this.state.page;
      const response = await fetch(url, {
         method: 'POST',
         headers : getHeadersAuthorization(this.cookieString)
      }).then(function(response) {
         if(response.status === 401) {
            //devo ri-instanziare Cookies perché ancora non esiste
            let cook = new Cookies();
            cook.remove('data', { path: '/' });
            window.location.replace("/login");
         } else {
            return response;
         }
      });
      const data = await response.json();
      var likesData={};
      
      for(var i=0;i<data.items.length;i++){
         likesData["e-"+data.items[i].id]={};
         //per sapere se l'utente loggato ha gia messo mi piace al post
         likesData["e-"+data.items[i].id]['value']=data.items[i].logged === 1 ? true : false;
         //per sapere quanti like ha il post
         likesData["e-"+data.items[i].id]['count']=data.items[i].likes;
      }

      this.setState({payload: data,
         loading: false,
         likes: likesData,
         tourEnjoyed: data.items[0].tour,
         page: this.state.page +1 
      });

   }

   trickToHideAppBanner() {
      //trick
      var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

      //banner nascosto sempre per l'app non è più su play store
      if (ios) {
        if (!standalone && safari) {
            // Safari
            let appBanner = document.getElementById('appBanner-wrapper');
            appBanner.style.display = 'none';
        } else if (!standalone && !safari) {
            // iOS webview
            let appBanner = document.getElementById('appBanner-wrapper');
            appBanner.style.display = 'none';
        }
      } else {
        if (userAgent.includes('wv')) {
            // Android webview
            let appBanner = document.getElementById('appBanner-wrapper');
            appBanner.style.display = 'none';
        } else {
            // Chrome
            let appBanner = document.getElementById('appBanner-wrapper');
            appBanner.style.display = 'none';
        }
      }
   }

   formatdata(date){
      let d = new Date(date);
      let today_year = new Date().getFullYear();
      let today_day = new Date().getDate();
      let today_month = new Date().getMonth() + 1;

      let da = d.getDate();
      let mo_number = d.getMonth() + 1;
      let mo = new Intl.DateTimeFormat('it', { month: 'short' }).format(d).toLowerCase();
      let ye = d.getFullYear();
      let hr = d.getHours();
      let mi = d.getMinutes();
      if(mi.toString().length===1){
         mi='0'+mi;
      }
      //let se = d.getSeconds();

      let timetostamp = '';
      let ieri_oggi = null;
      if(today_year > ye){
         timetostamp = da+' '+mo+' '+ye+' alle '+hr+':'+mi;
      } else {
         //se è nello stesso anno controllo se è anche lo stesso giorno per scrivere 'oggi' o 'ieri'
         if (da === today_day && mo_number === today_month) {
            ieri_oggi = 'oggi';
         } else if ((today_day-da) === 1 && mo_number === today_month) {
            ieri_oggi = 'ieri';
         }

         if(ieri_oggi) {
            timetostamp = ieri_oggi+' alle '+hr+':'+mi;
         } else {
            timetostamp = da+' '+mo+' alle '+hr+':'+mi;
         }
      }

      return timetostamp;
   }

   toggleShowVideo(url) {
      /* eslint-disable-next-line */
      this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
      this.setState({showVideo: this.state.showVideo});
    }

    renderVideo(item) {
      return (
        <div>
            <div className='video-wrapper'>
               {/* eslint-disable-next-line */}
               <a className='close-video' onClick={this.toggleShowVideo.bind(this, item.embedUrl)}></a>
               <iframe title={item.embedUrl} width='560' height='315' src={item.embedUrl} frameBorder='0' allowFullScreen></iframe>
            </div>
        </div>
      );
    }

    getElabMedia(blobs, videos) {
      let buffer= [];
      if(blobs.length > 1){
         for (let i = 0; i < blobs.length; i++) {
            buffer.push({original: 'https://res.cloudinary.com/racemood/image/upload/' + blobs[i]});
         }
      }else{
         buffer.push({original: 'https://res.cloudinary.com/racemood/image/upload/' + blobs});
      }
      if(videos){
         buffer.push(this.getVideo(videos));
      }
      return buffer;
   }

   getVideo(vids) {
      let buffer= [];
      if(vids.length > 1){
         for (let i = 0; i < vids.length; i++) {
            buffer.push({original: '', embedUrl: vids[i], renderItem: this.renderVideo.bind(this)});
         }
      }else{
         buffer.push({original: '', embedUrl: vids[0], renderItem: this.renderVideo.bind(this)});
      }
      return buffer[0];
   }

   async checkboxChange(event){

      var id_post = "e-"+event.target.attributes[0].value;
      let items = this.state.likes;
      items[id_post]['value'] = !items[id_post]['value'];
      if(items[id_post]['value']){
         items[id_post]['count']=items[id_post]['count']+1;
      }else{
         items[id_post]['count']=items[id_post]['count']-1;
      }
      this.setState({likes: items})
      
      const toPost={
         uid: this.data[0],
         id_post: id_post,
         action: items[id_post]['value']
      };

      const url = process.env.REACT_APP_API_HOST + "likeaction";
      let headers = getHeadersAuthorization(this.cookieString);
      headers['Content-Type'] = 'application/json';
      const response = await fetch(url, {
         method: 'POST',
         headers: headers,
         body: JSON.stringify(toPost)
      });
      // eslint-disable-next-line
      const data = await response.json();
   }

   async handleJoyrideCallback (data) {
      //quando finisce il tour setto sul db che l'ha svolto
      if (data.action ===  'reset') {
         this.setState({tourEnjoyed: !this.state.tourEnjoyed})
         const url = process.env.REACT_APP_API_HOST + "user/tourenjoyed/1/"+this.data[0];
         const response = await fetch(url, {
            method: 'POST',
            headers : getHeadersAuthorization(this.cookieString)
         });
         //eslint-disable-next-line
         const data = await response.json();
      }
   };

   fetchMoreData = async () => {

      //fare chiamata all'api
      const url = process.env.REACT_APP_API_HOST + "elaborations/getall/v2/"+this.data[0]+"/"+this.state.page;
      const response = await fetch(url, {
         method: 'POST',
         headers : getHeadersAuthorization(this.cookieString)
      }).then(function(response) {
         if(response.status === 401) {
            //devo ri-instanziare Cookies perché ancora non esiste
            let cook = new Cookies();
            cook.remove('data', { path: '/' });
            window.location.replace("/login");
         } else {
            return response;
         }
      });
      const data = await response.json();

      //popolare likes
      var likesData={};
      for(var i=0;i<data.items.length;i++){
         likesData["e-"+data.items[i].id]={};
         //per sapere se l'utente loggato ha gia messo mi piace al post
         likesData["e-"+data.items[i].id]['value']=data.items[i].logged === 1 ? true : false;
         //per sapere quanti like ha il post
         likesData["e-"+data.items[i].id]['count']=data.items[i].likes;
      }

      //se non ci sono risultati successivi settare hasMore a false
      if (data.items.length < 5) {
        this.setState({ hasMore: false });
      }

      //aggiungo i nuovi post a quelli esistenti
      let moreRecords = this.state.payload.items;
      Array.prototype.push.apply(moreRecords, data.items);

      //aggiungo i nuovi like a quelli esistenti
      let stateLikes = this.state.likes;
      let moreLikes = Object.assign({}, stateLikes, likesData);

      this.setState({
         payload: {items: moreRecords},
         likes: moreLikes,
         page: this.state.page + 1
      });
    };

   onAppBannerClose() {
      let cookieDateExpire = new Date();
      let cookieDaysExpire=5;
      cookieDateExpire.setDate(cookieDateExpire.getDate() + cookieDaysExpire);
      this.cookies.set('appBanner', cookieDateExpire, {path: "/", expires: cookieDateExpire});
   } 

   render(){ 
      return(
         <div>
            <Tour start={!this.state.tourEnjoyed} JoyrideCallback={this.handleJoyrideCallback.bind(this)} />
            {(this.props.visitor) ? <Header /> : ''}
            <div id="appBanner-wrapper">
               <AppBanner
                  appInfo={this.appInfo}
                  position='bottom'
                  onClose={this.onAppBannerClose.bind(this)}
                  hidden={!isMobile || !this.displayAppBanner}
                  style={{
                     zIndex: 10
                  }}
               />
            </div>
            <div className = "home-content">
               <div id="for-adv" className="row">
                  <div className="col-md-2 d-md-block d-none">
                     {/* <SideBanner /> */}
                  </div>
                  <div className = "col-md-8">
                     <div className = "container">
                        <div className="row">
                           <h1>Elaborazioni</h1>
                           <div className="ml-3 mt-2"><a className="btn btn-primary" href="/elaborations/add">+ crea</a></div>
                        </div>
                        
                        {this.state.loading || !this.state.payload ? (
                           <div className="item-centered">
                              <Spinner className="disc-name-color" animation="border" role="status">
                                 <span className="d-none">Loading...</span>
                              </Spinner>
                           </div>
                        ) : (
                           <div>
                              <InfiniteScroll
                                 dataLength={this.state.payload.items.length}
                                 //dataLength={16}
                                 next={this.fetchMoreData}
                                 style={{ overflow: 'hidden' }}
                                 hasMore={this.state.hasMore}
                                 loader={<div className="item-centered">
                                 <Spinner className="disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                 </Spinner>
                              </div>}
                                 endMessage={
                                    <p style={{ textAlign: "center", color: '#4b4b4e9c', fontWeight: '600' }}>
                                    Heyy! Al momento hai visto tutto
                                    </p>
                                 }
                              >
                              {this.state.payload.items.map((item, index) => (
                              <div key={item.id}>
                                 <div className="card">
                                    <ImageGallery 
                                       items={this.getElabMedia(JSON.parse(item.img), JSON.parse(item.videos))} 
                                       showThumbnails={false} 
                                       showIndex={true}
                                       showPlayButton={false} 
                                       showFullscreenButton={false} 
                                       additionalClass="image-fitted"/>
                                    <div className="card-body">
                                       <h5 className="card-title">{item.title}<div className="float-right text-muted d-none d-md-block">~{item.user}</div></h5>
                                       <p className="card-text">{item.description}</p>
                                       <p className="card-text">
                                          <small className="text-muted"><b className="d-inline d-md-none">{item.user+' · '}</b>{this.formatdata(item.created)}</small>
                                       </p>
                                       <div className="octane-like like-step">
                                          <img className="mr-2" src={tankImg} width="35px" height="35px" alt="tank"/>
                                          {this.state.likes["e-"+item.id]['count'] + " / 100 ottani"}
                                          <label className={"btn mb-0 "+ (this.state.likes["e-"+item.id]['value'] ? 'btn-dark':'btn-light')}>
                                             <input id={item.id} type="checkbox" className="like-button" onChange={this.checkboxChange.bind(this)} checked={this.state.likes["e-"+item.id]['value']} />
                                             +1 <i className="fas fa-tint"></i>
                                          </label>
                                       </div>
                                       <Comment id_elab={item.id} />
                                    </div>
                                 </div>
                                 {/* {( (index+1) % 2 === 0) ? (<div className="card"><div className="card-body adv"><center>Banner in vendita, <a href="/contactus">contattaci</a>.</center></div></div>): '' } */}
                              </div>
                              ))}
                              </InfiniteScroll>
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="col-md-2 d-md-block d-none">
                     {/* <SideBanner /> */}
                  </div>
               </div>
            </div>
            {(this.props.visitor) ? <Footer /> : ''}
         </div>
      ); 
   } 
}