import React, {useState, useEffect} from "react";
import CustomInput from "../../common/input/customInput";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function ContactUs (props){

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [charCounter, setCharCounter] = useState("0");
    const [feedback, setFeedback] = useState("");
    const [feedbackType, setfeedbackType] = useState("");
    const limitChars = 600;

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let uid = null;
    let config = null;

    if(cookie !== undefined){
        config = {
            headers: getHeadersAuthorization(cookie)
        };

        decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
        //data[0]=uid, data[1]=token, data[2]=username
        uid = decrypted.split('%')[0];
    }

    useEffect(() => {
        if(cookie !== undefined){
            axios.post(process.env.REACT_APP_API_HOST + "user/getuser", {uid: uid}, config)
            .then((response) => {
                setEmail(response.data.items[0].email);
            }).catch((error) => {
                if (error.response.status === 401) {
                    //devo ri-instanziare Cookies perché ancora non esiste
                    cookies.remove('data', { path: '/' });
                    window.location.replace("/login");
                }
            });
        }
    });
   
    const changeEmail = (event) => {
        setEmail(event.target.value);
    }

    const changeTitle = (event) => {
        if(event.target.value.length < 60){
            setTitle(event.target.value);
        }
    }

    const changeDescription = (event) => {
        var text_length = event.target.value.length;
        if(text_length < limitChars){
            setDescription(event.target.value);
        }
        setCharCounter(text_length);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        const toPost={
            uid: uid,
            email: email,
            title: title,
            description: description
        };

        if(title.length > 3 && description.length > 3){
            axios.post(process.env.REACT_APP_API_HOST + "contactus/add", toPost, config)
                .then((response) => {
                    if(response.data.response==="SUCCESS"){
                        setTitle('');
                        setDescription('');
                        setFeedback("Messaggio inviato con successo! Riceverai una risposta il prima possibile sull'email indicata.");
                        setfeedbackType("alert-success");
                    }else{
                        setFeedback("Errore durante l'invio del messaggio");
                        setfeedbackType("alert-danger");
                    }
                }).catch((error) => {
            });
        }else{
            setFeedback("Compila i campi richiesti");
            setfeedbackType("alert-danger");
        }
    }
    
    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                            <form onSubmit={onSubmit.bind(this)}>
                                <h2 className="form-title mb-2">Contattaci</h2>
                                <div className="text-muted form-title mb-4">Utilizza questa pagina per comunicare con noi. Puoi contattarci per qualsiasi motivo, come ad esempio segnalarci un errore o una miglioria da effettuare sul sito.</div>
                                <div className = "form-group">
                                    <CustomInput 
                                        type="email"
                                        name="email"
                                        label="Email"
                                        placeholder="La tua mail"
                                        value = {email || ''}
                                        onChange = {changeEmail.bind(this)}
                                        />
                                </div>
                                <div className = "form-group">
                                    <CustomInput 
                                        type="text"
                                        name="title"
                                        label="Oggetto"
                                        placeholder="Oggetto del messaggio"
                                        value = {title || ''}
                                        onChange = {changeTitle.bind(this)}
                                        />
                                </div>
                                <div className = "form-group">
                                    <textarea
                                        name="description"
                                        className="form-control"
                                        placeholder="Spiega perché ci stai contattando"
                                        style={{height: "130px"}}
                                        value = {description || ''}
                                        onChange = {changeDescription.bind(this)}
                                    />
                                    <div className="char-counter text-muted float-right">{charCounter}/{limitChars}</div>
                                </div>
                                <button 
                                    type="submit"
                                    className = "btn btn-primary">
                                    Invia
                                </button>
                            </form>
                            {feedback ? (
                                <div className={"alert " + feedbackType}>
                                    {feedback}
                                </div>
                            ):('')}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default ContactUs;
