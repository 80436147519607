import React from "react";

function ElabDetailsLocked(props) {

    const elab = props.elab;

    if (elab.length > 0) {
        return null;
    }

    return (
        <div className="login-to-show-elab pt-2">
            <div className="login-to-show-elab-title">
                Per visualizzare i componenti necessari per l'elaborazione
            </div>
            <div className="login-to-show-elab-btn mt-2">
            <a href="/login" className="btn btn-primary">Accedi</a> o <a href="/register" className="btn btn-primary">Registrati</a>
            </div>
        </div>
    );
}
export default ElabDetailsLocked;