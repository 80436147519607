import React from "react";
import NotFoundImage from "../../images/not-found.png";

function NotFoundPage (props){
    
    return(
        <div>
            <div className = "container pad_top_404">
                <div className = "row">
                    <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                        <div className="heading-404">
                            <h1>404</h1>
                        </div>
                        <h2>Oopss!</h2>
                        <p>La pagina che cercavi non è stata trovata.</p>
                        <div className="image404-wrapper">
                            <img src={NotFoundImage} width="100%" alt="404"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default NotFoundPage;
