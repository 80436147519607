import React from "react";
import ForgotPasswordService from "./forgotpasswordService";
import CustomInput from "../../common/input/customInput";
import RaceLogo from '../../images/race-icon-cutted.png';

export default class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
        }
        this.forgotPasswordService = new ForgotPasswordService();
        this.errors='';
    }

    changeText(field, event){
        this.setState({[field]:event.target.value.trim()});
    }

    /*onSubmit(event){
        event.preventDefault();
    }*/

    forgotPwSuccess(dataResult){
        this.setState({feedback: 'Ti abbiamo inviato un\'email per modificare la tua password. Se non la trovi, ti consigliamo di controllare la cartella spam', feedback_color: 'green'});
    }

    forgotPwError(errorData){
        this.setState({feedback: errorData, feedback_color: 'red'});
    }

    validateEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
    }

    subAction(event){
        event.preventDefault();
        if(this.state.email!==''){
            if(this.validateEmail(this.state.email)){
                this.forgotPasswordService.forgotPw(this.state.email, this.forgotPwSuccess.bind(this), this.forgotPwError.bind(this));
            }else{
                this.setState({feedback: 'Email non corretta', feedback_color: 'red'});    
            }
        }else{
            this.setState({feedback: 'Inserisci la mail', feedback_color: 'red'});
        }
    }
    
    render(){
        return(
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "accessForm col-md-8 col-lg-6 mr-auto ml-auto">
                            <img src={RaceLogo} className="col-md-12 mr-auto ml-auto mb-4" width={'400px'} alt="race-logo"></img>
                            <form /*onSubmit={this.onSubmit.bind(this)}*/>
                                <div className = "form-group">
                                    <CustomInput 
                                        type="email"
                                        //label="email"
                                        placeholder="email"
                                        value = {this.state.email || ''}
                                        onChange = {this.changeText.bind(this, "email")}
                                        />
                                </div>
                                <button 
                                    type="submit"
                                    className = "btn btn-primary mb-3"
                                    onClick={this.subAction.bind(this)}>
                                    Reimposta Password
                                </button>
                            </form>
                            <div style={{color:this.state.feedback_color}}>
                                {this.state.feedback}
                            </div>
                            <div className="row col-md-12"><a href="/login">Hai un account? Accedi</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}